@layer components {
  // Blue
  .w-card-img-primary {
    @apply bg-primary-600 dark-theme:bg-transparent;

    img {
      @apply opacity-90 dark-theme:mix-blend-color-dodge;
    }
  }
  .dark-theme .w-card-img-primary img {
    filter: opacity(0.8) drop-shadow(0 0 0 rgb(var(--colors-primary)));
  }

  // Orange
  .w-card-img-secondary {
    @apply bg-secondary-700 dark-theme:bg-transparent;

    img {
      @apply opacity-90 dark-theme:mix-blend-color-dodge;
    }
  }
  .dark-theme .w-card-img-secondary img {
    filter: opacity(0.8) drop-shadow(0 0 0 rgb(var(--colors-secondary)));
  }

  // Magenta
  .w-card-img-accent1 {
    @apply bg-accent1-600 dark-theme:bg-transparent;
    img {
      @apply opacity-90 dark-theme:mix-blend-color-dodge;
    }
  }
  .dark-theme .w-card-img-accent1 img {
    filter: opacity(0.8) drop-shadow(0 0 0 rgb(var(--colors-accent1)));
  }

  // Green
  .w-card-img-accent2 {
    @apply bg-accent2-800 dark-theme:bg-transparent;

    img {
      @apply opacity-90 dark-theme:mix-blend-color-dodge;
    }
  }
  .dark-theme .w-card-img-accent2 img {
    filter: opacity(0.8) drop-shadow(0 0 0 rgb(var(--colors-accent2)));
  }

  .card:has(div[class*="w-card-img-"]) {
    .card-content {
      @apply dark-theme:-mt-5 dark-theme:pt-0;
    }
  }

  article p {
    @apply sm:text-justify;
  }

  //Table of contents in blog posts
  //h2
  .toc > ul > li > a,
  .toc > ul > li::marker {
    @apply text-sm font-bold text-primary-800/80;
  }

  //h3
  .toc > ul > li > ul > li > a,
  .toc > ul > li > ul > li::marker {
    @apply text-sm font-bold text-auto-black/70;
  }

  //h4
  .toc > ul > li > ul > li > ul > li > a,
  .toc > ul > li > ul > li > ul > li::marker {
    @apply text-sm;
  }
}

// Slick Carousel Styling Override
.dark-theme .slick-dots li button:before {
  color: white !important;
}

.light-theme,
.high-contrast-theme {
  .slick-prev:before,
  .slick-next:before {
    color: black !important;
  }
}

.slick-track {
  display: flex !important;
  align-items: center;
}
