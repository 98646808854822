@tailwind base;
@tailwind components;
@tailwind utilities;

@import "_mui"; //mixin mui-styles()
@import "dark/mui"; //mixin dark-mui()
@import "animations";
@import "waleed.dev";

@include mui-styles();

@layer base {
  h1 {
    @apply pt-8 text-5xl font-light text-primary-800/80;
  }

  h2 {
    @apply pt-4 text-4xl font-bold text-primary-800/80;
  }

  h3 {
    @apply pt-4 text-3xl font-medium text-primary-800/80;
  }

  h4 {
    @apply pt-2 text-2xl font-medium text-primary-800/80;
  }

  h5 {
    @apply pt-2 text-xl font-medium text-primary-800/80;
  }

  h6 {
    @apply pt-2 text-lg font-medium text-primary-800/80;
  }

  p {
    @apply pt-2 text-base text-auto-black/80;
  }

  ol,
  ul {
    @apply pt-2 text-base text-auto-black/80;
  }

  li {
    @apply ml-5 list-item list-outside list-disc text-base text-auto-black/80;
  }

  .link {
    @apply text-primary-800/80 hover:text-primary-600/80 hover:underline hover:underline-offset-2;
  }
}

@layer components {
  .card {
    @apply flex flex-col overflow-hidden rounded bg-card-background/80 shadow-md backdrop-blur-lg;
  }

  .high-contrast-theme .card {
    @apply border border-primary-800 bg-card-background shadow-none;
  }

  .card-image {
    @apply h-32 w-full object-cover object-center;
  }

  .card-content {
    @apply p-4;
  }

  .card-actions {
    @apply mt-auto flex flex-row justify-end p-4;
  }

  .card-tags {
    @apply flex flex-row flex-wrap gap-2 p-4 pt-0;
  }

  .card-hoverable {
    @apply transition-all hover:-translate-y-0.5 hover:bg-card-background/90 hover:shadow-lg;
  }
}

article a {
  @apply text-primary-800/80 underline hover:text-primary-600/80 hover:underline hover:underline-offset-2 dark-theme:hover:text-primary-950/80 lg:no-underline;
}

// article a::after,
.external-link::after {
  content: "";
  width: 11px;
  height: 11px;
  margin-left: 4px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='black' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z'/%3E%3Cpath fill-rule='evenodd' d='M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}

.dark-theme .external-link::after {
  content: "";
  width: 11px;
  height: 11px;
  margin-left: 4px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z'/%3E%3Cpath fill-rule='evenodd' d='M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}

.dark-theme {
  @include dark-mui();
}
